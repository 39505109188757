import { NURSING_HOME_VISIT_LIST, NURSING_HOME_VISIT_UPDATE } from '../actions/types';

const initialState = {
  data: null,
  dataAll: []
};

export default function NursingHomeVisitReducer(state = initialState, action = {
  type: null
}) {
  switch (action.type) {
    case NURSING_HOME_VISIT_LIST:
    case NURSING_HOME_VISIT_UPDATE:
      return { ...state, ...action.playload };
    default:
      return { ...state };
  }
}
