import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  NURSING_HOME_VISIT_LIST,
  NURSING_HOME_VISIT_UPDATE
} from './types';

export const nursingHomeVisitList = nhcCode => async dispatch => {
  const url = `nursingHomeVisit?nhcCode=${nhcCode}`;

  dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_VISIT_LIST: true } });
  await axios.get(url.replace('?&', '?')).then(response => {
    dispatch({ type: NURSING_HOME_VISIT_LIST, playload: { dataAll: response?.data?.data || [] } });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_VISIT_LIST: false } });
  }).catch(err => {
    dispatch({ type: NURSING_HOME_VISIT_LIST, playload: { dataAll: [] } });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_VISIT_LIST: false } });
    let error = err;
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const nursingHomeVisitUpdate = (code, params) => async dispatch => {
  const url = `nursingHomeVisit/${code}`;

  dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_VISIT_UPDATE: true } });
  await axios.put(url, params).then(response => {
    let isUpdate = true;
    if (_.has(response.data, 'error')) {
      isUpdate = false;
    }

    dispatch({ type: NURSING_HOME_VISIT_UPDATE, playload: { isUpdate } });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_VISIT_UPDATE: false } });
  }).catch(err => {
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_VISIT_UPDATE: false } });
    let error = err;
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};
