import {
  PATIENT_FETCH,
  PATIENT_GET_HEALTH_RECORD,
  PATIENT_GET_DOCUMENT,
  PATIENT_CLEAR,
  PATIENT_UPDATE
} from '../actions/types';

const initialState = {
  data: [],
  detail: {},
  dataHealthRecords: [],
  dataDocumentFiles: [],
  isAddMore: false,
  nextKey: '',
  dataDupLists: []
};

export default function PatientReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case PATIENT_FETCH:
      return {
        ...state,
        ...action?.playload,
        data: action?.playload?.isAddMore ? [...state.data, ...action.playload.data] : action?.playload.data
      };
    case PATIENT_GET_HEALTH_RECORD:
    case PATIENT_GET_DOCUMENT:
    case PATIENT_UPDATE:
      return { ...state, ...action.playload };
    case PATIENT_CLEAR:
      return {
        ...state,
        dataHealthRecords: [],
        dataDocumentFiles: [],
        status: undefined,
        message: undefined,
        isAddMore: false,
        nextKey: ''
      };
    default:
      return { ...state };
  }
}
