import axios from 'axios';

export const searchPlaceInMaps = (search, key) => {
  const url = '/googlemap/search';
  return new Promise(reslove => {
    axios.post(url, { search, key }).then(response => {
      reslove(response.data);
    }).catch(() => {
      reslove({});
    });
  });
};
