import {
  HOSPITAL_LISTS,
  HOSPITAL_DATA
} from '../actions/types';

const initialState = {
  lists: [],
  data: []
};

export default function HospitalReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case HOSPITAL_LISTS:
    case HOSPITAL_DATA:
      return { ...state, ...action.playload };
    default:
      return { ...state };
  }
}
