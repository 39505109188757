import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  NURSING_HOME_BOOKING_CLEAR,
  NURSING_HOME_BOOKING_FETCH_ONE,
  NURSING_HOME_BOOKING_LIST,
  NURSING_HOME_BOOKING_UPDATE
} from './types';

export const nursingHomeBookingList = (nhcCode, query = null, limit = 15, nextKey = '') => async dispatch => {
  let isAddMore = false;
  let url = `nursingHomeBooking?nhcCode=${nhcCode}&limit=${limit}`;
  if (query) {
    url += `&${query}`;
  }
  if (nextKey !== '') {
    isAddMore = true;
    url += `&nextKey=${nextKey}`;
  }

  dispatch({ type: NURSING_HOME_BOOKING_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_BOOKING_LIST: true } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || [];
    dispatch({
      type: NURSING_HOME_BOOKING_LIST,
      playload: {
        data,
        status: 'success',
        isAddMore,
        nextKey: response?.data?.nextKey
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_LIST: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: NURSING_HOME_BOOKING_LIST, playload: { data: [], status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_LIST: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const nursingHomeBookingCreate = params => async dispatch => {
  const url = 'nursingHomeBooking';

  dispatch({ type: NURSING_HOME_BOOKING_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_BOOKING_UPDATE: true } });
  await axios.post(url, params).then(response => {
    const resData = response.data;
    const { message, data } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: NURSING_HOME_BOOKING_UPDATE, playload: { status, message, data } });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_UPDATE: false } });
  }).catch(err => {
    dispatch({ type: NURSING_HOME_BOOKING_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_UPDATE: false } });
    let error = err;
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const nursingHomeBookingUpdate = (code, params) => async dispatch => {
  const url = `nursingHomeBooking/${code}`;

  dispatch({ type: NURSING_HOME_BOOKING_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_BOOKING_UPDATE: true } });
  await axios.put(url, params).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: NURSING_HOME_BOOKING_UPDATE, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_UPDATE: false } });
  }).catch(err => {
    dispatch({ type: NURSING_HOME_BOOKING_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_UPDATE: false } });
    let error = err;
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const nursingHomeBookingCancel = (code, params) => async dispatch => {
  const url = `nursingHomeBooking/cancel/${code}`;

  dispatch({ type: NURSING_HOME_BOOKING_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_BOOKING_UPDATE: true } });
  await axios.put(url, params).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: NURSING_HOME_BOOKING_UPDATE, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_UPDATE: false } });
  }).catch(err => {
    dispatch({ type: NURSING_HOME_BOOKING_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_UPDATE: false } });
    let error = err;
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const getNursingHomeBookingByCode = code => async dispatch => {
  const url = `nursingHomeBooking?code=${code}`;

  dispatch({ type: NURSING_HOME_BOOKING_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_BOOKING_FETCH_ONE: true } });
  await axios.get(url).then(response => {
    const resData = response.data;
    const data = ((resData && resData.data) || []);
    const detail = data.find(o => o.nhbCode === code);
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: NURSING_HOME_BOOKING_FETCH_ONE, playload: { detail, status } });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_FETCH_ONE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_BOOKING_FETCH_ONE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};
