import { lazy } from 'react';

const routes = [
  { path: '/', Element: lazy(() => import('./views/pages/Dashboard')), roles: [] },
  { path: '/overview', Element: lazy(() => import('./views/pages/Overview')), roles: ['admin-hospital'] },
  { path: '/patient/:patCode', Element: lazy(() => import('./views/pages/Patients/Information')), roles: ['systemadmin', 'admin-hospital'] },
  { path: '/patient/edit/:patCode', Element: lazy(() => import('./components/Patient/Edit')), roles: ['admin-hospital', 'systemadmin'] },
  { path: '/rehab-center', Element: lazy(() => import('./views/rehab-center/components/RehabCenterSystemAdmin')), roles: ['systemadmin'] },
  { path: '/rehab', Element: lazy(() => import('./views/rehab-center/components/RehabCenterAdminHospital')), roles: ['admin-hospital'] },
  { path: '/rehab-center/add', Element: lazy(() => import('./views/rehab-center/RehabCenterAdd')), roles: ['systemadmin', 'admin-hospital'] },
  { path: '/rehab-center-detail/:code', Element: lazy(() => import('./views/rehab-center/RehabCenterDetail')), roles: ['systemadmin', 'admin-hospital'] },
  { path: '/rehab-room-detail/:index', Element: lazy(() => import('./views/rehab-center/RoomDetail')), roles: ['systemadmin', 'admin-hospital'] },
  { path: '/reservation', Element: lazy(() => import('./views/reservation/Reservation')), roles: ['admin-hospital'] },
  { path: '/reservation/add', Element: lazy(() => import('./views/reservation/Add')), roles: ['admin-hospital'] },
  { path: '/reservation/:code', Element: lazy(() => import('./views/reservation/Detail')), roles: ['admin-hospital'] },
  { path: '/reservation/edit/:code', Element: lazy(() => import('./views/reservation/Edit')), roles: ['admin-hospital'] },
  { path: '/package', Element: lazy(() => import('./views/pages/Packages')), roles: ['systemadmin', 'admin-hospital'] },
  { path: '/package/add', Element: lazy(() => import('./views/pages/Packages/Add')), roles: ['systemadmin', 'admin-hospital'] },
  { path: '/package/:code', Element: lazy(() => import('./views/pages/Packages/Info')), roles: ['systemadmin', 'admin-hospital'] },
  { path: '/package/edit/:code', Element: lazy(() => import('./views/pages/Packages/Edit')), roles: ['systemadmin', 'admin-hospital'] },
  { path: '/billing', Element: lazy(() => import('./views/pages/Billing')), roles: ['admin-hospital'] },
  { path: '/complaints', Element: lazy(() => import('./views/pages/Complaint')), roles: ['admin-hospital'] },
  { path: '/complaints/:code', Element: lazy(() => import('./views/pages/Complaint/Detail')), roles: ['admin-hospital'] }
];

export default routes;
