import {
  NURSING_HOME_CENTER_CLEAR,
  NURSING_HOME_CENTER_LIST,
  NURSING_HOME_CENTER_GET_BY_CODE,
  NURSING_HOME_CENTER_CREATE,
  NURSING_HOME_CENTER_UPDATE
} from '../actions/types';

const initialState = {
  data: null,
  dataAll: []
};

export default function NursingHomeCenterReducer(state = initialState, action = {
  type: null
}) {
  switch (action.type) {
    case NURSING_HOME_CENTER_LIST:
    case NURSING_HOME_CENTER_GET_BY_CODE:
    case NURSING_HOME_CENTER_CREATE:
    case NURSING_HOME_CENTER_UPDATE:
      return { ...state, ...action.playload };
    case NURSING_HOME_CENTER_CLEAR:
      return { ...state, message: undefined, status: undefined };
    default:
      return { ...state };
  }
}
