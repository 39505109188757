import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  PACKAGE_CLEAR,
  PACKAGE_FETCH,
  PACKAGE_UPDATE
} from './types';

const actionUrl = 'packages';

export const getPackages = (params = [], limit = 100, options = {
  attrType: 'full',
  columns: []
}, nextKey = '') => async dispatch => {
  let isAddMore = false;
  const newParams = [
    `limit=${limit}`,
    ...params
  ];
  let url = `${actionUrl}?${newParams.join('&')}`;

  if (options?.columns?.length > 0) {
    url += `&attrType=custom&${options.columns.map((column, key) => `attributes[${key}]=${column}`).join('&')}`;
  }
  if (nextKey !== '') {
    isAddMore = true;
    url += `&nextKey=${nextKey}`;
  }

  dispatch({ type: PACKAGE_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { PACKAGE_FETCH: true } });
  await axios.get(url).then(response => {
    const resData = response?.data || {};
    const data = (resData && resData.data) || [];
    dispatch({
      type: PACKAGE_FETCH,
      playload: {
        data,
        status: 'success',
        isAddMore,
        nextKey: resData?.nextKey
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { PACKAGE_FETCH: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { PACKAGE_FETCH: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const getPackageByCode = code => async dispatch => {
  const url = `${actionUrl}?code=${code}`;

  dispatch({ type: PACKAGE_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { PACKAGE_UPDATE: true } });
  await axios.get(url).then(response => {
    const resData = response.data;
    const data = ((resData && resData.data) || []);
    const detail = data.find(o => o.packageCode === code);
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: PACKAGE_UPDATE, playload: { detail, status } });
    dispatch({ type: IS_LOADING, status: false, names: { PACKAGE_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { PACKAGE_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const packageCreate = params => async dispatch => {
  const url = actionUrl;

  dispatch({ type: PACKAGE_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { PACKAGE_UPDATE: true } });
  await axios.post(url, params).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    let detail = {};
    if (resData?.data?.length > 0) {
      detail = resData?.data[0];
    }
    dispatch({ type: PACKAGE_UPDATE, playload: { status, message, detail } });
    dispatch({ type: IS_LOADING, status: false, names: { PACKAGE_UPDATE: false } });
  }).catch(err => {
    dispatch({ type: PACKAGE_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { PACKAGE_UPDATE: false } });
    let error = err;
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const packageUpdate = (code, dataSet) => async dispatch => {
  const url = `${actionUrl}/${code}`;

  dispatch({ type: PACKAGE_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { PACKAGE_UPDATE: true } });
  await axios.put(url, dataSet).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: PACKAGE_UPDATE, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { PACKAGE_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: PACKAGE_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { PACKAGE_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const packageDelete = code => async dispatch => {
  const url = `${actionUrl}/${code}`;

  dispatch({ type: PACKAGE_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { PACKAGE_UPDATE: true } });
  await axios.delete(url).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: PACKAGE_UPDATE, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { PACKAGE_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: PACKAGE_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { PACKAGE_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const pkgUpdate = async (code, dataSet) => {
  const url = `${actionUrl}/${code}`;
  return new Promise(reslove => {
    axios.put(url, dataSet).then(response => {
      reslove(response?.data?.data || response?.data);
    }).catch(() => {
      reslove({});
    });
  });
};
