import {
  NURSING_HOME_BOOKING_LIST,
  NURSING_HOME_BOOKING_FETCH_ONE,
  NURSING_HOME_BOOKING_UPDATE,
  NURSING_HOME_BOOKING_CLEAR
} from '../actions/types';

const initialState = {
  data: null,
  detail: null,
  dataAll: [],
  isAddMore: false,
  nextKey: ''
};

export default function NursingHomeCenterReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case NURSING_HOME_BOOKING_LIST:
      return {
        ...state,
        ...action?.playload,
        dataAll: action?.playload?.isAddMore ? [...state.dataAll, ...action.playload.data] : action?.playload.data
      };
    case NURSING_HOME_BOOKING_FETCH_ONE:
    case NURSING_HOME_BOOKING_UPDATE:
      return { ...state, ...action.playload };
    case NURSING_HOME_BOOKING_CLEAR:
      return {
        ...state,
        status: undefined,
        message: undefined,
        detail: null
      };
    default:
      return { ...state };
  }
}
