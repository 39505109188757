export const IS_LOADING = 'IS_LOADING';
export const ADMIN_UPDATE = 'ADMIN_UPDATE';
export const ADMIN_CREATE = 'ADMIN_CREATE';
export const ADMIN_FETCH_ONE = 'ADMIN_FETCH_ONE';
export const ADMIN_CLEAR = 'ADMIN_CLEAR';
export const ADMIN_FETCH = 'ADMIN_FETCH';
export const ALERT_CLEAR = 'ALERT_CLEAR';
export const ALERT_LISTS = 'ALERT_LISTS';
export const ALERT_NOTIFICATION_UPDATE = 'ALERT_NOTIFICATION_UPDATE';
export const AUTH_SIGN_IN = 'AUTH_SIGN_IN';
export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_CLEAR = 'AUTH_CLEAR';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const AUTH_FORGOT_PASSWORD_VERIFY_OTP = 'AUTH_FORGOT_PASSWORD_VERIFY_OTP';
export const AUTH_FORGOT_PASSWORD_CHANGE = 'AUTH_FORGOT_PASSWORD_CHANGE';
export const AUTH_ADD_CONTACT = 'AUTH_ADD_CONTACT';
export const AUTH_ADD_CONTACT_VERIFY = 'AUTH_ADD_CONTACT_VERIFY';
export const AUTH_ADD_CONTACT_UPDATE = 'AUTH_ADD_CONTACT_UPDATE';
export const AUTH_CHANGE_CONTACT_DEFAULT = 'AUTH_CHANGE_CONTACT_DEFAULT';
export const AUTH_CLOST_ACCOUNT = 'AUTH_CLOST_ACCOUNT';
export const COMPANIES_DATA = 'COMPANIES_DATA';
export const COMPANIES_DETAIL = 'COMPANIES_DETAIL';
export const COMPANIES_UPDATE = 'COMPANIES_UPDATE';
export const COMPANIES_CLEAR = 'COMPANIES_CLEAR';
export const MASTER_ALL = 'MASTER_ALL';
export const HOSPITAL_LISTS = 'HOSPITAL_LISTS';
export const HOSPITAL_DATA = 'HOSPITAL_DATA';
export const REFERRAL_FETCH = 'REFERRAL_FETCH';
export const REFERRAL_FETCH_ONE = 'REFERRAL_FETCH_ONE';
export const REFERRAL_LAST_FETCH = 'REFERRAL_LAST_FETCH';
export const REFERRAL_UPDATE = 'REFERRAL_UPDATE';
export const REFERRAL_CLEAR = 'REFERRAL_CLEAR';
export const REALTIME_DATA = 'REALTIME_DATA';
export const PATIENT_FETCH = 'PATIENT_FETCH';
export const PATIENT_GET_HEALTH_RECORD = 'PATIENT_GET_HEALTH_RECORD';
export const PATIENT_GET_DOCUMENT = 'PATIENT_GET_DOCUMENT';
export const PATIENT_CLEAR = 'PATIENT_CLEAR';
export const PATIENT_UPDATE = 'PATIENT_UPDATE';
export const AUTH_CREATE_ACCOUNT = 'AUTH_CREATE_ACCOUNT';
export const REALTIME_SIGNAL = 'REALTIME_SIGNAL';
export const REALTIME_SIGNAL_GUEST = 'REALTIME_SIGNAL_GUEST';
export const NURSING_HOME_CENTER_CLEAR = 'NURSING_HOME_CENTER_CLEAR';
export const NURSING_HOME_CENTER_LIST = 'NURSING_HOME_CENTER_LIST';
export const NURSING_HOME_CENTER_GET_BY_CODE = 'NURSING_HOME_CENTER_GET_BY_CODE';
export const NURSING_HOME_CENTER_CREATE = 'NURSING_HOME_CENTER_CREATE';
export const NURSING_HOME_CENTER_UPDATE = 'NURSING_HOME_CENTER_UPDATE';
export const NURSING_HOME_CENTER_REMOVE = 'NURSING_HOME_CENTER_REMOVE';
export const NURSING_HOME_BOOKING_LIST = 'NURSING_HOME_BOOKING_LIST';
export const NURSING_HOME_BOOKING_FETCH_ONE = 'NURSING_HOME_BOOKING_FETCH_ONE';
export const NURSING_HOME_BOOKING_UPDATE = 'NURSING_HOME_BOOKING_UPDATE';
export const NURSING_HOME_BOOKING_CLEAR = 'NURSING_HOME_BOOKING_CLEAR';
export const NURSING_HOME_VISIT_LIST = 'NURSING_HOME_VISIT_LIST';
export const NURSING_HOME_VISIT_UPDATE = 'NURSING_HOME_VISIT_UPDATE';
export const PACKAGE_CLEAR = 'PACKAGE_CLEAR';
export const PACKAGE_FETCH = 'PACKAGE_FETCH';
export const PACKAGE_UPDATE = 'PACKAGE_UPDATE';
export const MASTER_NURSING_HOME_ILLNESS = 'MASTER_NURSING_HOME_ILLNESS';
