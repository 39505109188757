import {
  AUTH_SIGN_IN,
  AUTH_SIGN_OUT,
  AUTH_REGISTER,
  AUTH_CHANGE_PASSWORD,
  AUTH_FORGOT_PASSWORD,
  AUTH_FORGOT_PASSWORD_VERIFY_OTP,
  AUTH_FORGOT_PASSWORD_CHANGE,
  AUTH_ADD_CONTACT,
  AUTH_ADD_CONTACT_VERIFY,
  AUTH_ADD_CONTACT_UPDATE,
  AUTH_CHANGE_CONTACT_DEFAULT,
  AUTH_CLOST_ACCOUNT,
  AUTH_CLEAR,
  AUTH_CREATE_ACCOUNT
} from '../actions/types';

const initialState = {
  loggedIn: false,
  accessToken: null,
  isHasRoleAccess: true,
  profile: {},
  roles: [],
  status: undefined
};
export default function AuthReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case AUTH_REGISTER:
    case AUTH_SIGN_IN:
    case AUTH_CHANGE_PASSWORD:
    case AUTH_FORGOT_PASSWORD:
    case AUTH_FORGOT_PASSWORD_VERIFY_OTP:
    case AUTH_FORGOT_PASSWORD_CHANGE:
    case AUTH_ADD_CONTACT:
    case AUTH_ADD_CONTACT_VERIFY:
    case AUTH_ADD_CONTACT_UPDATE:
    case AUTH_CHANGE_CONTACT_DEFAULT:
    case AUTH_CLOST_ACCOUNT:
      return { ...state, ...action.playload };
    case AUTH_CREATE_ACCOUNT:
      return { ...state, ...action.playload };
    case AUTH_SIGN_OUT:
      return { state };
    case AUTH_CLEAR:
      return {
        ...state,
        message: undefined,
        status: undefined,
        isRegisterSuccess: undefined,
        isHasRoleAccess: true,
        isChangePasswordSuccess: undefined
      };
    default:
      return { ...state };
  }
}
