import { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { database, firebase } from '../helpers/firebase';
import {
  alert,
  setListsNotification,
  updateRealtime,
  masterAll,
  masterNursingHomeIllness
} from '../actions';

const InitialComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(selector => selector.auth);

  const initFirebase = useRef(false);

  const renderAlert = useCallback(value => {
    const timezone = localStorage.getItem('timezone') || moment.tz.guess();
    const data = {
      title: value?.title,
      message: value?.message.join(', '),
      icon: 'ion:information-circle',
      link: `/consult?data=${value.activity.refCode}`,
      datetime: moment.utc(value.createdAt).tz(timezone),
      fill: false,
      type: 'warning'
    };
    if (value?.status) {
      if (value.status === 'NEW_BOOKING') {
        data.title = t('New appointments');
        data.message = value.message.join(', ');
        data.icon = 'fluent:calendar-checkmark-16-filled';
        data.type = 'success';
        dispatch(updateRealtime('referral-update', true));
      } else if (value.status === 'CHANGE_INFORMATION') {
        data.title = t('Change information appointment');
        data.message = `#${value.activity.refCode}`;
        data.icon = 'ion:information-circle';
        data.type = 'warning';
        dispatch(updateRealtime('referral-update', true, { refCode: value.activity.refCode }));
      } else if (value.status === 'CONFIRM_CHANGE_BOOKING') {
        data.title = t('Patient confirm change appointment');
        data.message = `#${value.activity.refCode}`;
        data.icon = 'fluent:calendar-checkmark-16-filled';
        dispatch(updateRealtime('referral-update', true, { refCode: value.activity.refCode }));
      } else if (value.status === 'CHANGE_BOOKING') {
        data.title = t('Patient wants to change the appointment');
        data.message = `#${value.activity.refCode}`;
        data.icon = 'fluent:calendar-arrow-right-16-filled';
        data.type = 'warning';
        dispatch(updateRealtime('referral-update', true, { refCode: value.activity.refCode }));
      } else if (value.status === 'CANCEL_BOOKING') {
        data.title = t('The consultation appointment was canceled');
        data.message = `#${value.activity.refCode}`;
        data.icon = 'fluent:calendar-arrow-right-16-filled';
        data.type = 'danger';
        dispatch(updateRealtime('referral-update', true, { refCode: value.activity.refCode }));
      } else if (value.status === 'CONFIRM_REQUEST_CALL_NOW') {
        data.title = t('Patient accept request call now');
        data.message = `#${value.activity.refCode}`;
        data.type = 'success';
        dispatch(updateRealtime('referral-update', true, { refCode: value.activity.refCode }));
      } else if (value.status === 'REJECT_REQUEST_CALL_NOW') {
        data.title = t('Patient reject request call now');
        data.message = `#${value.activity.refCode}`;
        data.type = 'danger';
      } else if (value.status === 'CONFIRM_REQUEST_HEALTH_RECORD') {
        data.title = t('Patient accept request for access to health information');
        data.message = `#${value.activity.refCode}`;
        data.type = 'success';
        dispatch(updateRealtime('referral-update', true, { refCode: value.activity.refCode }));
      } else if (value.status === 'REJECT_REQUEST_HEALTH_RECORD') {
        data.title = t('Patient reject request for access to health information');
        data.message = `#${value.activity.refCode}`;
        data.type = 'danger';
      } else if (value.status === 'CALL_NOW') {
        data.title = t('The patient calls you now');
        data.message = `#${value.activity.refCode}`;
        data.type = 'warning';
      }
    }
    alert(data);
  }, [dispatch, t, updateRealtime]);

  useEffect(() => {
    dispatch(masterAll());
    dispatch(masterNursingHomeIllness());
  }, [dispatch]);

  useEffect(() => {
    if (auth.profile?.adminCode && !initFirebase.current) {
      initFirebase.current = true;

      const notifications = firebase.query(firebase.ref(database, 'notifications'), firebase.orderByChild('to_code'), firebase.equalTo(auth.profile.adminCode), firebase.limitToLast(1));
      firebase.onChildAdded(notifications, snapshot => {
        const value = snapshot.val();
        if (value.readBy.includes('NO-READ')) {
          renderAlert(value);
          value.readBy = [auth.profile.adminCode];
          value.updatedAt = moment().format('YYYY-MM-DD HH:mm:ss');
          firebase.update(firebase.ref(database, `notifications/${snapshot.key}`), value);
        }
      });

      const listNotifications = [];
      const lists = firebase.query(firebase.ref(database, 'notifications'), firebase.orderByChild('to_code'), firebase.equalTo(auth.profile.adminCode), firebase.limitToLast(100));
      firebase.onChildAdded(lists, snapshot => {
        const value = snapshot.val();
        if (value?.status) {
          listNotifications.push({ value, key: snapshot.key });
          dispatch(setListsNotification(listNotifications));
        }
      });
    }
  }, [auth.profile]);
};

export default InitialComponent;
