import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  COMPANIES_DATA,
  COMPANIES_CLEAR,
  COMPANIES_UPDATE,
  COMPANIES_DETAIL
} from './types';

export const getCompanies = (codes, params = [], limit = 100, nextKey = '') => async dispatch => {
  let isAddMore = false;
  let url = 'companies';
  if (codes) {
    url += `?code=${codes}`;
  } else {
    const newParams = [
      `limit=${limit}`,
      ...params
    ];
    url += `?${newParams.join('&')}`;
  }
  if (nextKey !== '') {
    isAddMore = true;
    url += `&nextKey=${nextKey}`;
  }

  dispatch({ type: COMPANIES_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { COMPANIES_DATA: true } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || response.data || [];
    dispatch({
      type: COMPANIES_DATA,
      playload: {
        data,
        status: 'success',
        isAddMore,
        nextKey: response?.data?.nextKey
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { COMPANIES_DATA: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: COMPANIES_DATA, playload: { status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { COMPANIES_DATA: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const getCompany = companyCode => async dispatch => {
  const url = `companies?code=${companyCode}`;

  dispatch({ type: COMPANIES_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { COMPANIES_DETAIL: true } });
  await axios.get(url).then(response => {
    const resData = response.data;
    const data = ((resData && resData.data) || []);
    const detail = data.find(o => o.companyCode === companyCode);
    dispatch({ type: COMPANIES_DETAIL, playload: { status: 'success', detail } });
    dispatch({ type: IS_LOADING, status: false, names: { COMPANIES_DETAIL: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: COMPANIES_DETAIL, playload: { status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { COMPANIES_DETAIL: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const companyCreate = dataSet => async dispatch => {
  const url = 'companies';

  dispatch({ type: COMPANIES_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { COMPANIES_UPDATE: true } });
  await axios.post(url, dataSet).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: COMPANIES_UPDATE, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { COMPANIES_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: COMPANIES_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { COMPANIES_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const companyUpdate = (code, dataSet) => async dispatch => {
  const url = `companies/${code}`;

  dispatch({ type: COMPANIES_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { COMPANIES_UPDATE: true } });
  await axios.put(url, dataSet).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: COMPANIES_UPDATE, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { COMPANIES_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: COMPANIES_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { COMPANIES_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};
