import {
  PACKAGE_CLEAR,
  PACKAGE_FETCH,
  PACKAGE_UPDATE
} from '../actions/types';

const initialState = {
  data: [],
  detail: {},
  nextKey: ''
};

export default function PackagesReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case PACKAGE_FETCH:
      return {
        ...state,
        ...action?.playload,
        data: action?.playload?.isAddMore ? [...state.data, ...action.playload.data] : action?.playload.data
      };
    case PACKAGE_UPDATE:
      return { ...state, ...action.playload };
    case PACKAGE_CLEAR:
      return {
        ...state,
        status: undefined,
        message: undefined,
        isAddMore: false,
        nextKey: ''
      };
    default:
      return { ...state };
  }
}
