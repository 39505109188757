import React from 'react';
import { createRoot } from 'react-dom/client';
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import { Amplify } from 'aws-amplify';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { authValidateToken, authAccessToken, authSignOut } from './actions';
import App from './App';
import reportWebVitals from './reportWebVitals';
import reducer from './reducers';
import AWSConfig from './configs/aws';
import './helpers/axios';
import './i18n';

require('moment/locale/th');

Amplify.configure(AWSConfig.config);
Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_ENV,
  enabledReleaseStages: ['production', 'staging', 'development']
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const authValidToken = (store, accessToken) => {
  store.dispatch({ type: 'IS_LOADING', status: true, names: { AUTH_VALIDATE_TOKEN: true } });
  authValidateToken(accessToken).then(response => {
    if (typeof response.error !== 'undefined') {
      store.dispatch(authSignOut());
    } else {
      response.role = 'admin';
      store.dispatch(authAccessToken(response, accessToken));
    }
    store.dispatch({ type: 'IS_LOADING', status: false, names: { AUTH_VALIDATE_TOKEN: false } });
  }).catch(() => {
    store.dispatch(authSignOut());
    store.dispatch({ type: 'IS_LOADING', status: false, names: { AUTH_VALIDATE_TOKEN: false } });
  });
};

const logger = store => next => action => {
  const state = store.getState();
  if (state.auth && state.auth.accessToken && state.auth.accessToken !== '' && !state.auth.loggedIn) {
    authValidToken(store, state.auth.accessToken);
  }
  return next(action);
};

const middlewares = [reduxThunk, logger];
const store = createStore(reducer, {}, applyMiddleware(...middlewares));

createRoot(document.getElementById('root')).render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
