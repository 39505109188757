import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import { IS_LOADING, MASTER_ALL, MASTER_NURSING_HOME_ILLNESS } from './types';

export const masterAll = () => async dispatch => {
  const url = 'master';

  dispatch({ type: IS_LOADING, status: true, names: { MASTER_ALL: true } });
  await axios.get(url).then(response => {
    const data = _.has(response, 'data') ? response.data : {};
    dispatch({ type: MASTER_ALL, playload: data });
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_ALL: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_ALL: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const masterNursingHomeIllness = () => async dispatch => {
  const url = 'master/nursingHomeIllness';

  dispatch({ type: IS_LOADING, status: true, names: { MASTER_NURSING_HOME_ILLNESS: true } });
  await axios.get(url).then(response => {
    dispatch({ type: MASTER_NURSING_HOME_ILLNESS, playload: { illnesses: _.has(response.data, 'data') ? response.data.data : [] } });
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_NURSING_HOME_ILLNESS: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_ALL: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};
