import { combineReducers } from 'redux';
import LoadingReducer from './LoadingReducer';
import MasterReducer from './MasterReducer';
import AdminReducer from './AdminReducer';
import AlertReducer from './AlertReducer';
import AuthReducer from './AuthReducer';
import CompaniesReducer from './CompaniesReducer';
import HospitalReducer from './HospitalReducer';
import ReferralReducer from './ReferralReducer';
import RealtimeReducer from './RealtimeReducer';
import PatientReducer from './PatientReducer';
import NursingHomeCenterReducer from './NursingHomeCenterReducer';
import NursingHomeBookingReducer from './NursingHomeBookingReducer';
import NursingHomeVisitReducer from './NursingHomeVisitReducer';
import PackagesReducer from './PackagesReducer';

export default combineReducers({
  admin: AdminReducer,
  companies: CompaniesReducer,
  hospital: HospitalReducer,
  referral: ReferralReducer,
  patient: PatientReducer,
  loading: LoadingReducer,
  master: MasterReducer,
  alert: AlertReducer,
  auth: AuthReducer,
  realtime: RealtimeReducer,
  nursingHomeCenter: NursingHomeCenterReducer,
  nursingHomeBooking: NursingHomeBookingReducer,
  nursingHomeVisit: NursingHomeVisitReducer,
  packages: PackagesReducer
});
