import React, {
  lazy,
  Suspense,
  useEffect,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useCookies } from 'react-cookie';

import routes from './routes';
import './App.scss';
import 'bootstrap';

import Loading from './components/Loading';
import InitialComponent from './components/InitialComponent';
import { hospitalLists } from './actions';
import { SUB_DOMAIN } from './configs';

const AppLayout = lazy(() => import('./layouts/AppLayout'));
const NotFound = lazy(() => import('./views/errors/NotFound'));
const Login = lazy(() => import('./views/auth/Login'));

let toastifyPosition = 'top-right';

const App = () => {
  const [loadAuth, setLoadAuth] = useState(false);
  const [currentRole, setCurrentRole] = useState();

  const { i18n } = useTranslation();
  const auth = useSelector(selector => selector.auth);
  const loading = useSelector(selector => selector.loading);
  const dispatch = useDispatch();
  const [cookie] = useCookies();

  const isLoading = () => {
    if (loading.status) return true;
    if (_.filter(_.values(loading.names), name => name).length > 0) return true;
    return false;
  };

  useEffect(() => {
    dispatch(hospitalLists());
  }, []);

  useEffect(() => {
    if (auth?.accessToken || cookie[`${SUB_DOMAIN}-accessToken`]) {
      setLoadAuth(true);
    } else {
      setLoadAuth(false);
    }
  }, [auth]);

  useEffect(() => {
    setCurrentRole(cookie?.currentRole);
  }, [cookie]);

  useEffect(() => {
    if (/Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      toastifyPosition = 'top-center';
    }
  }, [navigator]);

  useEffect(() => {
    const useLang = localStorage.getItem('i18nextLng') === null || localStorage.getItem('i18nextLng').indexOf('-') !== -1 ? 'th' : localStorage.getItem('i18nextLng');
    i18n.changeLanguage(useLang);
    moment.locale(useLang);
  }, [i18n]);

  return (
    <>
      {(isLoading()) && (<Loading message={loading.message} />)}
      <ToastContainer hideProgressBar draggable={false} position={toastifyPosition} />

      <BrowserRouter>
        <InitialComponent />
        <Routes>
          <Route index path="/login" element={<Login />} />
          <Route path="/" element={(<Suspense fallback={null}><AppLayout /></Suspense>)}>
            {loadAuth && currentRole && (
              routes.filter(route => !route?.roles?.length || (route?.roles?.length > 0 && route?.roles.includes(currentRole))).map(({ path, Element }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <Element />
                  }
                />
              ))
            )}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};
export default App;
