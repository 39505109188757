import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  HOSPITAL_LISTS,
  HOSPITAL_DATA
} from './types';

export const hospitalLists = () => async dispatch => {
  const url = 'hospitals/lists';

  dispatch({ type: IS_LOADING, status: true, names: { HOSPITAL_LISTS: true } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || [];
    dispatch({ type: HOSPITAL_LISTS, playload: { lists: data } });
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_LISTS: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_LISTS: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const hospitalData = () => async dispatch => {
  const url = 'hospitals';

  dispatch({ type: IS_LOADING, status: true, names: { HOSPITAL_DATA: true } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || response.data || [];
    dispatch({ type: HOSPITAL_DATA, playload: { data } });
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_DATA: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_DATA: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const getHospitals = codes => async dispatch => {
  let url = 'hospitals';
  if (codes) {
    if (typeof codes === 'string') {
      url += `?code=${codes}`;
    } else if (codes?.length > 0) {
      url += `?${codes.map((val, key) => `hospitals[${key}]=${val}`).join('&')}`;
    }
  }

  dispatch({ type: IS_LOADING, status: true, names: { HOSPITAL_DATA: true } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || response.data || [];
    dispatch({ type: HOSPITAL_DATA, playload: { data } });
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_DATA: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_DATA: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};
