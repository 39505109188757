import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  NURSING_HOME_CENTER_LIST,
  NURSING_HOME_CENTER_GET_BY_CODE,
  NURSING_HOME_CENTER_CREATE,
  NURSING_HOME_CENTER_UPDATE,
  NURSING_HOME_CENTER_REMOVE,
  NURSING_HOME_CENTER_CLEAR
} from './types';

export const nursingHomeCenterList = () => async dispatch => {
  const url = 'nursingHomeCenter?limit=1000';

  dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_CENTER_LIST: true } });
  await axios.get(url.replace('?&', '?')).then(response => {
    dispatch({ type: NURSING_HOME_CENTER_LIST, playload: { dataAll: response?.data?.data || [] } });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_CENTER_LIST: false } });
  }).catch(err => {
    dispatch({ type: NURSING_HOME_CENTER_LIST, playload: { dataAll: [] } });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_CENTER_LIST: false } });
    let error = err;
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const nursingHomeCenterGetByCode = code => async dispatch => {
  const url = `nursingHomeCenter?code=${code}`;

  dispatch({ type: NURSING_HOME_CENTER_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_CENTER_GET_BY_CODE: true } });
  await axios.get(url).then(response => {
    const resData = response.data;
    const { message } = resData;
    const data = ((resData && resData.data) || []);
    const detail = data.find(o => o.nhcCode === code);
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: NURSING_HOME_CENTER_GET_BY_CODE, playload: { data: detail, message, status } });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_CENTER_GET_BY_CODE: false } });
  }).catch(err => {
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_CENTER_GET_BY_CODE: false } });
    let error = err;
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const nursingHomeCenterCreate = params => async dispatch => {
  const url = 'nursingHomeCenter';

  dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_CENTER_CREATE: true } });
  await axios.post(url, params).then(response => {
    dispatch({ type: NURSING_HOME_CENTER_CREATE, playload: { data: response.data.data } });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_CENTER_CREATE: false } });
  }).catch(err => {
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_CENTER_CREATE: false } });
    let error = err;
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const nursingHomeCenterUpdate = (code, params) => async dispatch => {
  const url = `nursingHomeCenter/${code}`;

  dispatch({ type: NURSING_HOME_CENTER_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_CENTER_UPDATE: true } });
  await axios.put(url, params).then(response => {
    let isUpdate = true;
    if (_.has(response.data, 'error')) {
      isUpdate = false;
    }
    const resData = response.data;
    const data = (resData?.data || resData);
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({
      type: NURSING_HOME_CENTER_UPDATE,
      playload: {
        isUpdate,
        message,
        status,
        data
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_CENTER_UPDATE: false } });
  }).catch(err => {
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_CENTER_UPDATE: false } });
    let error = err;
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const nursingHomeCenterRemove = code => async dispatch => {
  const url = `nursingHomeCenter/${code}`;

  dispatch({ type: IS_LOADING, status: true, names: { NURSING_HOME_CENTER_REMOVE: true } });
  await axios.delete(url).then(() => {
    dispatch({ type: NURSING_HOME_CENTER_REMOVE });
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_CENTER_REMOVE: false } });
  }).catch(err => {
    dispatch({ type: IS_LOADING, status: false, names: { NURSING_HOME_CENTER_REMOVE: false } });
    let error = err;
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const nhcUpdate = async (nhcCode, dataSet) => {
  const url = `/nursingHomeCenter/${nhcCode}`;
  return new Promise(reslove => {
    axios.put(url, dataSet).then(response => {
      reslove(response?.data?.data || response?.data);
    }).catch(() => {
      reslove({});
    });
  });
};
