import { MASTER_ALL, MASTER_NURSING_HOME_ILLNESS } from '../actions/types';

const initialState = {};

export default function MasterReducer(state = initialState, action = { type: undefined }) {
  switch (action.type) {
    case MASTER_ALL:
    case MASTER_NURSING_HOME_ILLNESS:
      return { ...state, ...action.playload };
    default:
      return { ...state };
  }
}
